import { Drawer, Input, notification } from 'antd';
import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Badge, Space } from 'antd';
import { ReactComponent as BellIcon } from '../assets/images/icons/bell.svg';
import { ReactComponent as CartIcon } from '../assets/images/icons/cart.svg';
import { ReactComponent as MobileCartIcon } from '../assets/images/icons/mobilecart.svg';
import { ReactComponent as MobileMenuIcon } from '../assets/images/icons/mobilemenu.svg';
import { ReactComponent as MobileNotificationIcon } from '../assets/images/icons/mobilenotification.svg';
import { ReactComponent as Cancel } from '../assets/images/icons/x.svg';
import Logo from '../assets/images/logo.png';
import { addToCart } from '../slices/cartSlice';
import SubHeader from './SubHeader';

import { _get_profile, _get_user_notification, logout_user } from '../common/axios_services';
import { fetchData, profileData } from '../slices/profileSlice';
import { default as AppRoutes, default as routes } from "./routes";

const Navbar = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const cart = useSelector((state) => state.userCart.cart);
    const fetchDataStatus = useSelector((state) => state.userProfile.fetch);
    const userData = useSelector((state) => state.userProfile.dataFetched);
    const [open, setOpen] = useState(false);
    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [notificationData, setNotificationData] = useState([]);
    let jwt_token = localStorage.getItem('konnect_token');
    const [fixedNav, setFixedNav] = useState(false);
    const [subcategories] = useState(0);

    // const onSearch = (value) => {
    //     navigate(`/shop?search=${value}`);
    // };

    const onClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        dispatch(addToCart(JSON.parse(localStorage.getItem('konnect_cart'))));
    }, [dispatch, jwt_token]);

    const logout = async () => {
        try {
            const user_logout = await logout_user();
            openNotificationWithIcon('success', user_logout?.data?.message);
        } catch (err) { }
        localStorage.removeItem('konnect_token');
        navigate('/signin');
    };

    const fetchUser = async () => {
        try {
            const user_profile = await _get_profile();
            dispatch(fetchData(true));
            dispatch(profileData(user_profile?.data?.data));
        } catch (err) {
            dispatch(profileData(false));
            dispatch(fetchData(false));
            if (!err.response) openNotificationWithIcon('error', err?.message);
        }
    };

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: '',
            placement: 'bottomRight',
            description: message,
        });
    };

    const getUserNotifications = async () => {
        if (fetchDataStatus) {
            if (userData) {
                try {
                    let userNotifs = await _get_user_notification({ page: 1, per_page: 10 });
                    setNotificationData(userNotifs.data.data);
                } catch (err) {
                    if (err.response) {
                    } else {
                        openNotificationWithIcon('error', err?.message);
                    }
                }
            }
        }
    };

    useEffect(() => {
        fetchUser();
        getUserNotifications();
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            const offset = window.scrollY;
            if (offset > 200) {
                setFixedNav(true);
            }
            else {
                setFixedNav(false);
            }
        })
    }, []);

    return (
        <div className={`nav-display top-0 z-[100] sticky`}>
            {/* <div
                className={`navigation ${!props.new ? "" : props.noShadow ? 'no-shadow' : ''} ${props.adjustNavSize ? 'adjustPadding' : ''
                    } ${fixedNav ? 'fixed' : ''}`}
            > */}
            <div className={`navigation px-28 py-3 flex items-center border-b-4 border-solid border-[#F3F4F6] justify-between w-full ${fixedNav ? 'fixed' : ''}`}>
                <div className="logo_bar flex gap-16 items-center">
                    <Link to={`/`}>
                        <img src={Logo} className="logo" alt="konnect logo" />
                    </Link>
                    <div className="desktop">
                        <ul className="flex items-center gap-5">
                            <li>
                                <Link to="/restaurant">Restaurant</Link>
                            </li>
                            <li>
                                <Link to={`${AppRoutes.shop}`}> Shop </Link>
                            </li>
                            <li>
                                <Link to={`${AppRoutes.wise9ja}`}> Wise9ja </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* <Input.Search placeholder="search product name" className="desktop show-all" onSearch={onSearch} /> */}
                <div className="desktop">
                    <ul className="flex items-center gap-5">
                        <li className="navi">
                            {userData ? (
                                <div onClick={() => setOpenNotificationModal(true)}>
                                    <Badge count={notificationData.length}>
                                        <BellIcon style={{ height: '2rem' }} />
                                    </Badge>
                                </div>
                            ) : (
                                <Link to={`${AppRoutes.signin}`}>
                                    <BellIcon style={{ height: '2rem' }} />
                                </Link>
                            )}
                        </li>
                        {
                            props.showCart && (
                                <li className="navi cart-absolute">
                                    <Link className="cartIcon" to={props.restaurantCart ? `/restaurant/1` : `/cart`}>
                                        {cart ? (
                                            <Badge count={cart.data.length}>
                                                <CartIcon style={{ height: '2rem' }} />
                                            </Badge>
                                        ) : (
                                            <Badge count={0}>
                                                <CartIcon style={{ height: '2rem' }} />
                                            </Badge>
                                        )}
                                    </Link>
                                </li>
                            )
                        }



                        {fetchDataStatus ? (
                            userData ? (
                                <li style={{ paddingRight: 0 }} className="navi cart-absolute">
                                    <Link to={AppRoutes.profile_loyalty} className="px-14 py-6 button_like">
                                        <Space>
                                            Hi, {userData.first_name.trim()}
                                        </Space>
                                    </Link>
                                </li>
                            ) : (
                                <li style={{ paddingRight: 0 }} className="navi cart-absolute">
                                    <Link to={AppRoutes.profile_loyalty} className="px-14 py-6 button_like">
                                        <span>
                                            Hi, {userData.first_name.trim()}
                                        </span>
                                    </Link>
                                </li>
                            )
                        ) : (
                            <>
                                <li>
                                    <Link className="auth-na" to={`${AppRoutes.signin}`}>
                                        Sign In
                                    </Link>
                                </li>
                                <li>
                                    <Link className="px-14 py-6 auth-na button_like" to={`${AppRoutes.signup}`}>
                                        Create a free account
                                    </Link>
                                </li>
                            </>
                        )}
                    </ul>
                </div>
                <div className="mobile show-all">
                    <div className="other-nav">
                        {
                            props.showCart ? (
                                <div>
                                    <span className="navi cart-absolute">
                                        <Link className="cartIcon" to={`${AppRoutes.cart}`}>
                                            {cart ? (
                                                <Badge count={cart.data.length}>
                                                    <MobileCartIcon style={{ height: '2.3rem' }} />
                                                </Badge>
                                            ) : (
                                                <Badge count={0}>
                                                    <MobileCartIcon style={{ height: '2.3rem' }} />
                                                </Badge>
                                            )}
                                        </Link>
                                    </span>
                                </div>
                            ) : ''
                        }

                        {/* <div>
                            <span className="navi cart-absolute">
                                <Link className="cartIcon" to={`${AppRoutes.cart}`}>
                                    {cart ? (
                                        <Badge count={cart.data.length}>
                                            <MobileCartIcon style={{ height: '2.3rem' }} />
                                        </Badge>
                                    ) : (
                                        <Badge count={0}>
                                            <MobileCartIcon style={{ height: '2.3rem' }} />
                                        </Badge>
                                    )}
                                </Link>
                            </span>
                        </div> */}
                        <div>
                            {userData ? (
                                <div onClick={() => setOpenNotificationModal(true)}>
                                    <Badge count={notificationData.length}>
                                        <BellIcon style={{ height: '2rem' }} />
                                    </Badge>
                                </div>
                            ) : (
                                <Link to={`${AppRoutes.signin}`}>
                                    <MobileNotificationIcon className="auth-na" style={{ height: '2.5rem' }} />
                                </Link>
                            )}
                        </div>
                        <div className="lastNav" onClick={() => setOpen(true)}>
                            <MobileMenuIcon style={{ height: '2.5rem' }} />
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="mobile">
                <div className="search">
                    <Input.Search placeholder="search product name" onSearch={onSearch} />
                </div>
            </div> */}
            <Drawer
                title={
                    <div className="mobile-grid">
                        <div>
                            <Link to="/">
                                <img src={Logo} style={{ maxWidth: "135px" }} alt="logo" className="logo" />
                            </Link>
                        </div>
                        <Cancel onClick={() => onClose()} className="cancel" />
                    </div>
                }
                placement={'left'}
                closable={false}
                onClose={onClose}
                open={open}
                key={'right'}
            >
                <div className="mobile-bottom-nav">
                    <ul>
                        {fetchDataStatus ? (
                            userData && (
                                <>
                                    <li>
                                        <div>
                                            <h2>Hi, {userData?.first_name.trim()}</h2>
                                        </div>
                                    </li>
                                    {userData?.kyc_status === "verified" && (
                                        <li onClick={() => setOpenNotificationModal(!openNotificationModal)}>
                                            <Link to="/profile/loyalty">
                                                <p style={{ fontSize: "14px" }}>Wallet</p>
                                            </Link>
                                        </li>
                                    )}
                                    {
                                        userData?.is_restaurant === true && (
                                            <li>
                                                <Link to="/restaurant/dashboard" exact>
                                                    <p>My Restaurant</p>
                                                </Link>
                                            </li>

                                        )
                                    }

                                    {userData?.kyc_status === "verified" && (
                                        <li>
                                            <Link to={AppRoutes.profile_referral}>
                                                <p>Referral </p>
                                            </Link>
                                        </li>
                                    )}
                                    <li>
                                        <Link to={AppRoutes.profile_orders}>
                                            <p>Order</p>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/profile/overview" exact>
                                            <p>Profile</p>
                                        </Link>
                                    </li>
                                </>
                            )
                        ) : (
                            <li>
                                <Link to={`${AppRoutes.signin}`}>
                                    <p>Sign in</p>
                                </Link>
                            </li>
                        )}
                        <li>
                            <Link to={AppRoutes.restaurant} >
                                <p>Restaurants</p>
                            </Link>
                        </li>
                        <li>
                            <Link to={AppRoutes.shop}>
                                <p>Shop</p>
                            </Link>
                        </li>
                        <li>
                            <Link to={`${AppRoutes.wise9ja}`}>
                                <p>Wise9ja</p>
                            </Link>
                        </li>
                        <li>
                            <Link to={AppRoutes.contact}>
                                <p>Contact us</p>
                            </Link>
                        </li>

                        {/* <li>
                            <Link to={AppRoutes.policies}>
                                <p>Privacy policy</p>
                            </Link>
                        </li>
                        <li>
                            <Link to={AppRoutes.terms}>
                                <p>Terms of use</p>
                            </Link>
                        </li> */}
                        {fetchDataStatus && userData && (
                            <li>
                                <Link
                                    onClick={(e) => {
                                        e.preventDefault();
                                        logout();
                                    }}
                                >
                                    <p style={{ fontWeight: "600" }}>Sign Out</p>
                                </Link>
                            </li>
                        )}
                    </ul>
                    {fetchDataStatus ? (
                        ""
                    ) : (
                        <div className="bottom-link">
                            <Link to={AppRoutes.signup}>Create a free account</Link>
                        </div>
                    )}
                </div>
            </Drawer>
            <Drawer
                title={
                    <div className="mobile-grid nav-notif-grid">
                        <Link to="/">
                            <img src={Logo} style={{ maxWidth: "135px" }} alt="logo" className="logo" />
                        </Link>
                        <div style={{ cursor: 'pointer' }}>
                            <Cancel onClick={() => setOpenNotificationModal(false)} className="cancel" />
                        </div>
                    </div>
                }
                placement={'right'}
                closable={false}
                onClose={() => setOpenNotificationModal(false)}
                open={openNotificationModal}
                key={'notifDrawer'}
            >
                <div className="notifData">
                    {notificationData.length ? (
                        <div></div>
                    ) : (
                        <div className="emptyData">
                            <p>You have no new notifications</p>
                        </div>
                    )}
                </div>
            </Drawer>
            {/* {props.showSubNav ? <SubHeader subcategories={subcategories} /> : ''} */}
        </div>
    );
};

const mapStateToProps = (store) => {
    return { user: store.userProfile };
};

export default connect(mapStateToProps)(Navbar);
