import 'antd/dist/antd.css';
// import '../assets/css/form.css';

import { ArrowRightOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Spin, Tabs, notification } from 'antd';
import NaijaStates from 'naija-state-local-government';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../../../common/axios_call';
import { _get_all_state, _validate_referral_code } from '../../../common/axios_services';
import PhoneInput from 'react-phone-input-2';
import { FaArrowLeftLong } from "react-icons/fa6";


const SignupForm = (props) => {
    const [form] = Form.useForm()
    let url = new URLSearchParams(window.location.search);
    let navigate = useNavigate();
    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;

    const [errorMessage, setErrorMessage] = useState('');
    const [appLoading, setAppLoading] = useState(false);
    // const [referralCode,] = useState(url.get('konnectrfc') ? url.get('konnectrfc') : '');
    const [referralCode, setReferralCode] = useState(() => {
        const codeFromURL = url.get('konnectrfc'); // Get from URL
        if (codeFromURL) {
            localStorage.setItem('referralCode', codeFromURL); // Save to localStorage
            return codeFromURL;
        }
        return localStorage.getItem('referralCode') || ''; // Fallback to localStorage
    });
    const [redirectUrl,] = useState(url.get('konnectrd') ? url.get('konnectrd') : '');
    const [referralDetails, setReferralDetails] = useState(false);
    const [loadingReferralDetails, setLoadingReferralDetails] = useState(false);
    const [allStates,] = useState(NaijaStates.states());
    const [allLgas, setAllLgas] = useState([]);
    const [isSo, setIsSo] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [displayType, setDisplayType] = useState("users");


    const openNotificationWithIcon = (type, title, message) => {
        notification[type]({
            placement: 'bottomRight', message: title ? title : '', description: message ? message : "",
        });
    };

    // Effect to handle changes to referral code (optional, in case you want dynamic updates)
    useEffect(() => {
        if (referralCode) {
            localStorage.setItem('referralCode', referralCode);
        }
    }, [referralCode]);


    const updateReferralCode = async (values) => {
        if (values?.referralCode?.length === 6) {
            setLoadingReferralDetails(true)
            try {
                const validate_referral_code = await _validate_referral_code({ code: `${values.referralCode}` })
                setLoadingReferralDetails(false)
                setErrorMessage(false)
                if (validate_referral_code?.data?.data?.is_sl) {
                    setReferralDetails(validate_referral_code?.data?.data)
                } else {
                    setReferralDetails(false)
                    form.setFieldsValue({
                        state: "",
                        lga: "",
                        home_address: "",
                    });
                }
            } catch (err) {
                form.setFieldsValue({
                    referralCode: "",
                });
                setReferralDetails(false)
                setLoadingReferralDetails(false)
                if (err.response) {
                    setErrorMessage(err?.response?.data?.message)
                } else {
                    openNotificationWithIcon('error', "Something went wrong", err.message)
                }
            }
        } else if (values?.referralCode?.length < 6) {
            setLoadingReferralDetails(false)
            setReferralDetails(false)
            form.setFieldsValue({
                state: "",
                lga: "",
                home_address: "",
            });
        }
    }
    const signupUser = (values) => {
        setAppLoading(true);
        setErrorMessage('');
        let { first_name, last_name, email, password, referralCode, home_address, lga, state } = values;
        axios.post('/auth/sign-up/user', { lga, state, first_name, last_name, email, password, confirm_password: password, referral_code: referralCode, image: "", home_address, phone_code: countryCode, phone_number: phoneNumber, role_id: 1, is_ra: false, app: "web", is_so: isSo }).then((res) => {
            setAppLoading(false);
            if (res?.data?.status === "error") {
                return setErrorMessage(res.data.message)
            }

            localStorage.setItem('konnectnewaccountcreated', 'true');
            navigate('/verify-email');
        }).catch((err) => {
            if (err.response) {
                if (err?.response?.data?.message === "Unauthorized") {
                    setErrorMessage(err?.response?.data?.message)
                } else {
                    setErrorMessage(err?.response?.data?.message)
                }
            } else {
                setErrorMessage(false)
                openNotificationWithIcon('error', "Something went wrong", err.message);
            }
            setAppLoading(false);
        })
    }
    const onChangeState = (value) => {
        if (value) {
            const allLGAsinAState = NaijaStates.lgas(`${value}`);
            setAllLgas(allLGAsinAState.lgas);
        }
    }

    useEffect(() => {
        setIsSo(props.so);
    }, [])

    const handlePhoneChange = (phone, country) => {
        const dialCode = `${country.dialCode}`;
        setCountryCode(dialCode);

        // Remove the country code from the start of the phone number
        const phoneWithoutCode = phone.startsWith(dialCode)
            ? phone.slice(dialCode.length).trim()
            : phone;
        setPhoneNumber(phoneWithoutCode);
    };

    const toggleDisplayView = (val) => {
        if (val === "users") {
            setIsSo(false);
            setDisplayType("users");
        } else {
            setIsSo(true);
            setDisplayType("business");
        }
    }
    return (
        <div className="form-pages signup-data-page">
            <div className="">
                <div className="">
                    <div className="">
                        <div className="">
                            <div className="form aut">
                                <div>
                                    <div className="form-tex">
                                        <h3 className="text-2xl font-bold mb-8">Create a free {props.so ? "Business" : "Customer"} Account!</h3>
                                        {/* <p className="text-sm leading-loose md:leading-relaxed mb-8">Fill in your details with your preferred email address that can be verified, thereafter check your email to claim your sign-up bonus!</p> */}
                                        {errorMessage ?
                                            <p className="form-error">{errorMessage}</p> : ''
                                        }
                                    </div>
                                    <Form form={form} onValuesChange={updateReferralCode} onFinish={signupUser} initialValues={{ home_address: "", state: "", lga: "" }} layout="vertical">
                                        {/* <button type="button" onClick={() => setIsSo(!isSo)} className="text-white text-[13px] font-gilroyBold focus:outline-none  py-2 px-5 rounded-xl bg-[#1f6b2b] mb-4">
                                            {!isSo ? 'Sign up as a Business' : 'Sign up as a user'}
                                            <ArrowRightOutlined style={{ marginLeft: "3px" }} />
                                        </button> */}
                                        <div className="auth-group grid grid-cols-2 gap-3">
                                            <div className=" space">
                                                <label htmlFor="first_name">First name</label>
                                                <Form.Item name="first_name">
                                                    <Input placeholder='First Name' style={{ height: '3.4rem' }} className="rounded-lg text-sm" />
                                                </Form.Item>
                                            </div>
                                            <div className="">
                                                <label htmlFor="last_name">Last name (Surname)</label>
                                                <Form.Item name="last_name">
                                                    <Input placeholder='Last Name' style={{ height: '3.4rem' }} className="rounded-lg text-sm" />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className="auth-group grid grid-cols-2 gap-3">
                                            <div className=" space">
                                                <label htmlFor="email">Email Address</label>
                                                <Form.Item name="email">
                                                    <Input type="email" placeholder='Email Address' style={{ height: '3.4rem' }} className="rounded-lg text-sm" />
                                                </Form.Item>
                                            </div>
                                            <div className=" space">
                                                <label htmlFor="phone_number">Phone Number</label>
                                                <Form.Item name="phone_number">
                                                    <PhoneInput
                                                        country={'ng'}
                                                        value={phoneNumber}
                                                        onChange={handlePhoneChange}
                                                        containerStyle={{ width: '100%' }}
                                                        inputStyle={{ height: '3.4rem', width: '100%' }}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className="">
                                            <label htmlFor="password">Password</label>
                                            <Form.Item name="password">
                                                <Input.Password placeholder='Password' style={{ height: '3.4rem' }} className="rounded-lg text-sm" />
                                            </Form.Item>
                                        </div>
                                        <div className="auth-group">
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <label htmlFor="referralCode">Referral code (If referred by a friend)</label>
                                                {loadingReferralDetails ?
                                                    <Button disabled loading type='text'>Loading</Button> :
                                                    referralDetails &&
                                                    <span style={{ margi: "0px", textTransform: "capitalize" }}>{referralDetails?.name}</span>
                                                }
                                            </div>
                                            <Form.Item name="referralCode">
                                                <Input name="referralCode" placeholder='Referral code' maxLength={6} style={{ height: '3.4rem' }} className="rounded-lg text-sm" />
                                            </Form.Item>
                                        </div>
                                        {/* <div className="auth-group form-group">
                                            <label htmlFor="home_address">Vendor Address</label>
                                            <Form.Item name="home_address" rules={[{ required: isSo ? true : false, message: 'Please input Vendor Address!' }]}>
                                                <Input placeholder='Vendor Address' style={{ height: '3.4rem' }} className="rounded-lg text-sm" />
                                            </Form.Item>
                                        </div> */}
                                        {/* <div className="grid grid-cols-2 gap-3">
                                            <div className="auth-group form-group space">
                                                <label htmlFor="state">State</label>
                                                <Form.Item name="state" rules={[{ required: isSo ? true : false, message: 'Please input your state!' }]}>
                                                    <Select placeholder="Select State" onChange={onChangeState} name="state" style={{ width: '100%', height: '3.4rem' }} className="rounded-lg text-sm">
                                                        <Select.Option key={0} value="lagos">Lagos</Select.Option>
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div className="auth-group form-group">
                                                <label htmlFor="lga">LGA</label>
                                                <Form.Item name="lga" rules={[{ required: isSo ? true : false, message: 'Please input your LGA!' }]}>
                                                    <Select placeholder="Select LGA" name="lga" style={{ width: '100%', height: '3.4rem' }} className="rounded-lg text-sm">
                                                        {allLgas?.map((lga, index) => (
                                                            <Select.Option key={index} value={lga}>{lga}</Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </div> */}
                                        <div style={{ marginTop: '5%' }}></div>
                                        {/* <label style={{ marginBottom: "10px", display: "flex", alignItems: "center", gap: "10px" }}>
                                            <b style={{ fontSize: "14px", color: "red" }}>Become a food vendor? Click here</b> <input type="checkbox" style={{ width: "20px", height: "20px" }} checked={isSo} onChange={() => { setIsSo(!isSo) }} />
                                        </label> */}
                                        {/* <p className="text-sm"> By clicking Create Account, you acknowledge you have read and agreed to our <Link to={`/terms?konnectrfc=${referralCode}&konnectrd=${redirectUrl}`}>Terms of Use</Link> and <Link to={`/privacy-policy/?konnectrfc=${referralCode}&konnectr {redirectUrl}`}>Privacy Policy</Link>.</p> */}
                                        {
                                            appLoading || loadingReferralDetails ?
                                                <button loading disabled style={{ height: '3.5rem', width: '100%', borderRadius: '4px', color: "red" }} className="bg-main text-white w-full h-[3.5rem] rounded-[4px] text-sm">
                                                    Please be patient, sign-up in  progress
                                                </button>
                                                :
                                                <button htmlType="submit" className="bg-main text-white w-full h-[3.5rem] rounded-[4px] text-sm" >Create account</button>
                                        }
                                        <div className="text-center text-main text-sm mt-5 cursor-pointer" onClick={props.toggleView}>
                                            <h4 className='flex gap-3 items-center justify-center'><FaArrowLeftLong /> Go Back</h4>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </div >
    )
}

export default SignupForm;