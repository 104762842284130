import Navbar from "../components/Navbar";
import React, { useEffect, useState } from "react";
import { LocationWrapper } from "../components/LocationWrapper";
import { Col, Drawer, Modal, notification, Row, Select, Rate } from "antd";
import { RestaurantCardDeals } from "../components/RestaurantCardDeals";
import { FoodImageArea } from "../components/FoodImageArea";
import Footer from "../components/Footer-new";
import { useLocation, useNavigate } from "react-router-dom";
import { _get_cities_by_state_code, _restaurants_in_lga } from "../common/axios_services";
import DiscountModal from "../components/DiscountModal";
import ValentineImg from "../assets/images/vALENTInes.png";
import Promo from "../assets/images/refer.png";
import BonusImg from "../assets/images/bonus.png";
import CheckoutImg from "../assets/images/checkout.png";
import FoodImg from "../assets/images/_1.png";
// import DiscountModal from "../components/DiscountModal";
import { FaStar, FaTimes } from "react-icons/fa";
import Layout from "../components/redesign/layout";

const Restaurant = () => {
    const [all_lgas, set_all_lgas] = useState([]);
    const [selectedLGA, setSelectedLGA] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [restaurants, setRestaurants] = useState([]); // State for restaurants
    const location = useLocation();
    const Navigate = useNavigate();
    const [isDiscountModalVisible, setIsDiscountModalVisible] = useState(false);

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            description: message,
        });
    };


    // Fetch LGAs for Lagos using the API
    const fetchCities = async (state_code = "LA", keyword = "") => {
        try {
            const uploadedCities = await _get_cities_by_state_code(state_code, keyword);
            set_all_lgas(uploadedCities.data.data);
        } catch (err) {

            openNotificationWithIcon("error", err.message);
            set_all_lgas([]);
        }
    };

    const fetchRestaurants = async (lga) => {
        try {
            const response = await _restaurants_in_lga({
                page: 1,
                per_page: 1000,
                state: "Lagos",
                lga: lga,
            });
            const lgaParts = lga.split("&").map((part) => part.trim().toLowerCase());
            let filteredRestaurants = response.data.data.filter((restaurant) => {
                return (
                    restaurant.city &&
                    lgaParts.some((lgaPart) => restaurant.city.toLowerCase().includes(lgaPart)) &&
                    restaurant.status === "approved" && // Include only approved restaurants
                    restaurant.is_open === true // Ensure only approved restaurants are included
                );
            });
            // Check if no restaurants are found
            if (filteredRestaurants.length === 0) {
                setRestaurants([]); // Set state to an empty array
            } else {
                setRestaurants(filteredRestaurants); // Update the restaurants state
            }
            return filteredRestaurants;
        } catch (err) {
            openNotificationWithIcon("error", err.message);
            setRestaurants([]); // Clear restaurants in case of an error
            return [];
        }
    };


    const toggleLocationModal = () => setIsModalVisible(!isModalVisible)


    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const lgaFromQuery = params.get("lga");

        const initializeData = async () => {
            if (lgaFromQuery) {
                setSelectedLGA(lgaFromQuery);
                localStorage.setItem("selectedLGA", lgaFromQuery);
                const filteredRestaurants = await fetchRestaurants(lgaFromQuery);
                if (filteredRestaurants.length === 0) {
                    setRestaurants([]); // If no data, ensure it's empty
                    openNotificationWithIcon('info', 'No restaurants available for this location.');
                }
            } else {
                setIsModalVisible(true);
                setRestaurants([]); // No location selected
            }

            await fetchCities(); // Fetch available LGAs
        };

        initializeData();
    }, [location.search]);

    const handleLGAChange = async () => {
        if (selectedLGA) {
            // setSelectedLGA(value); // Update selected LGA
            localStorage.setItem("selectedLGA", selectedLGA);
            const filteredRestaurants = await fetchRestaurants(selectedLGA); // Fetch restaurants for the selected LGA
            setRestaurants(filteredRestaurants); // Update the restaurants state

            // Close the drawer after selection
            if (selectedLGA) {
                setIsModalVisible(false);
                // setIsDiscountModalVisible(true);
            }
        } else {
            openNotificationWithIcon('error', 'Please select location');
        }
    };

    return (
        <>
            {/* <div style={{ backgroundColor: '#F2F2F2' }}> */}
            <div>
                <Layout>
                    <div className="px-24 mt-14">
                        {/* <div className="h-[40rem] mt-20 w-full bg-black rounded-3xl"></div> */}
                        <div className="relative">
                            <img src={ValentineImg} alt="promo" className="w-full h-full object-fit rounded-3xl" />
                            {/* <div className="z-40 absolute top-0 left-0 pt-20 w-full">
                                <div>
                                    <h2 className="text-white text-6xl leading-normal text-center mb-5">Enjoy Quality Meal Discounts This Valentine</h2>
                                    <div className="flex items-center gap-10 w-[50%] flex-col mx-auto">
                                        <input type="text" className="w-full py-10 h-[12rem] px-2 flex-1 rounded-xl" />
                                        <button className="py-4 px-8 h-[6rem] text-white w-max text-xl rounded-xl bg-[#258635]">Search Restaurants</button>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="absolute w-full opacity-50 h-full bg-black rounded-3xl inset-0"></div> */}
                        </div>
                    </div>
                    <div className="mt-16 px-24">
                        <div className="grid grid-cols-3 gap-10">
                            <img src={Promo} alt="promo" className="w-full h-full object-fit rounded-xl" />
                            <img src={BonusImg} alt="bonus" className="w-full h-full object-fit rounded-xl" />
                            <img src={CheckoutImg} alt="checkout" className="w-full h-full object-fit rounded-xl" />
                        </div>
                    </div>
                    <div className="mt-16 px-24">
                        <div className="">
                            <h4 className="text-2xl mb-2 font-bold">
                                Restaurants Near You
                            </h4>
                            <div className="grid grid-cols-3 gap-10">
                                <div className="border border-solid border-[#DBDADE] rounded-xl">
                                    <img src={FoodImg} alt="promo" className="w-full h-[12rem] object-cover object-fit rounded-xl" />
                                    <div className="px-3 py-3">
                                        <h3 className="text-lg font-medium">The Night Train</h3>
                                        <ul className="mt-2 flex gap-3 items-center">
                                            <li className="text-sm text-black opacity-80">&bull; African</li>
                                            <li className="text-sm text-black opacity-80">&bull; Contemporary</li>
                                            <li className="text-sm text-black opacity-80">&bull; African</li>
                                        </ul>
                                        <ul className="mt-2 flex gap-3 justify-between items-center">
                                            <li className="flex items-center gap-2"><FaStar className="text-base leading-normal text-[#258635]" />
                                                <h5 className="text-base mb-0 leading-normal">5.0</h5>
                                            </li>
                                            <li className="text-base font-medium">&bull; Open</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="border border-solid border-[#DBDADE] rounded-xl">
                                    <img src={FoodImg} alt="promo" className="w-full h-[12rem] object-cover object-fit rounded-xl" />
                                    <div className="px-3 py-3">
                                        <h3 className="text-lg font-medium">The Night Train</h3>
                                        <ul className="mt-2 flex gap-3 items-center">
                                            <li className="text-sm text-black opacity-80">&bull; African</li>
                                            <li className="text-sm text-black opacity-80">&bull; Contemporary</li>
                                            <li className="text-sm text-black opacity-80">&bull; African</li>
                                        </ul>
                                        <ul className="mt-2 flex gap-3 justify-between items-center">
                                            <li className="flex items-center gap-2"><FaStar className="text-base leading-normal text-[#258635]" />
                                                <h5 className="text-base mb-0 leading-normal">5.0</h5>
                                            </li>
                                            <li className="text-base font-medium">&bull; Open</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="border border-solid border-[#DBDADE] rounded-xl">
                                    <img src={FoodImg} alt="promo" className="w-full h-[12rem] object-cover object-fit rounded-xl" />
                                    <div className="px-3 py-3">
                                        <h3 className="text-lg font-medium">The Night Train</h3>
                                        <ul className="mt-2 flex gap-3 items-center">
                                            <li className="text-sm text-black opacity-80">&bull; African</li>
                                            <li className="text-sm text-black opacity-80">&bull; Contemporary</li>
                                            <li className="text-sm text-black opacity-80">&bull; African</li>
                                        </ul>
                                        <ul className="mt-2 flex gap-3 justify-between items-center">
                                            <li className="flex items-center gap-2"><FaStar className="text-base leading-normal text-[#258635]" />
                                                <h5 className="text-base mb-0 leading-normal">5.0</h5>
                                            </li>
                                            <li className="text-base font-medium">&bull; Open</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-16 mb-20">
                            <h4 className="text-2xl mb-2 font-bold">
                                All Restaurants
                            </h4>
                            <div className="grid grid-cols-3 gap-10">
                                <div className="border border-solid border-[#DBDADE] rounded-xl">
                                    <img src={FoodImg} alt="promo" className="w-full h-[12rem] object-cover object-fit rounded-xl" />
                                    <div className="px-3 py-3">
                                        <h3 className="text-lg font-medium">The Night Train</h3>
                                        <ul className="mt-2 flex gap-3 items-center">
                                            <li className="text-sm text-black opacity-80">&bull; African</li>
                                            <li className="text-sm text-black opacity-80">&bull; Contemporary</li>
                                            <li className="text-sm text-black opacity-80">&bull; African</li>
                                        </ul>
                                        <ul className="mt-2 flex gap-3 justify-between items-center">
                                            <li className="flex items-center gap-2"><FaStar className="text-base leading-normal text-[#258635]" />
                                                <h5 className="text-base mb-0 leading-normal">5.0</h5>
                                            </li>
                                            <li className="text-base font-medium">&bull; Open</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="border border-solid border-[#DBDADE] rounded-xl">
                                    <img src={FoodImg} alt="promo" className="w-full h-[12rem] object-cover object-fit rounded-xl" />
                                    <div className="px-3 py-3">
                                        <h3 className="text-lg font-medium">The Night Train</h3>
                                        <ul className="mt-2 flex gap-3 items-center">
                                            <li className="text-sm text-black opacity-80">&bull; African</li>
                                            <li className="text-sm text-black opacity-80">&bull; Contemporary</li>
                                            <li className="text-sm text-black opacity-80">&bull; African</li>
                                        </ul>
                                        <ul className="mt-2 flex gap-3 justify-between items-center">
                                            <li className="flex items-center gap-2"><FaStar className="text-base leading-normal text-[#258635]" />
                                                <h5 className="text-base mb-0 leading-normal">5.0</h5>
                                            </li>
                                            <li className="text-base font-medium">&bull; Open</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="border border-solid border-[#DBDADE] rounded-xl">
                                    <img src={FoodImg} alt="promo" className="w-full h-[12rem] object-cover object-fit rounded-xl" />
                                    <div className="px-3 py-3">
                                        <h3 className="text-lg font-medium">The Night Train</h3>
                                        <ul className="mt-2 flex gap-3 items-center">
                                            <li className="text-sm text-black opacity-80">&bull; African</li>
                                            <li className="text-sm text-black opacity-80">&bull; Contemporary</li>
                                            <li className="text-sm text-black opacity-80">&bull; African</li>
                                        </ul>
                                        <ul className="mt-2 flex gap-3 justify-between items-center">
                                            <li className="flex items-center gap-2"><FaStar className="text-base leading-normal text-[#258635]" />
                                                <h5 className="text-base mb-0 leading-normal">5.0</h5>
                                            </li>
                                            <li className="text-base font-medium">&bull; Open</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal
                        open={isModalVisible}
                        footer={false}
                        onCancel={() => setIsModalVisible(false)} // Allow manual closure
                        className="restaurantModal edit"
                        centered={true}
                    >
                        <div>
                            <div onClick={() => setIsModalVisible(false)}
                                className="mb-16 cursor-pointer border-4 border-solid border-[#F3F3F3] rounded-full size-14 flex items-center justify-center">
                                <FaTimes className="text-2xl" />
                            </div>
                            <h2 className="font-bold flex items-center mt-8 mb-2 text-xl">
                                Select Your Location
                            </h2>
                            <div className="">
                                <Select
                                    value={selectedLGA}
                                    onChange={(value) => setSelectedLGA(value)}
                                    className="w-full rest_searchDrawe bg-white h-[4rem] flex items-center rounded-xl border-2 border-solid border-[#F3F3F3]"
                                    placeholder="Select Location"
                                    disabled={all_lgas.length === 0} // Disable if no LGAs are available
                                >
                                    {all_lgas.length === 0 ? (
                                        <Select.Option value="" disabled>
                                            Select Location
                                        </Select.Option>
                                    ) : (
                                        all_lgas.map((lga) => (
                                            <Select.Option key={lga.id} value={lga.city_name}>
                                                {lga.city_name}
                                            </Select.Option>
                                        ))
                                    )}
                                </Select>

                                <button onClick={handleLGAChange} className="mt-3 bg-main text-white py-4 rounded-xl w-full text-base">Add Location</button>
                            </div>
                        </div>
                    </Modal>
                </Layout>
            </div>

            {/*<Modal*/}
            {/*    open={isModalVisible }*/}
            {/*    footer={false}*/}
            {/*    onCancel={() => setIsModalVisible(false)}*/}
            {/*    className="restaurantModal"*/}
            {/*>*/}
            {/*  <h2 className="text-green-800 font-bold flex items-center my-4 mt-8 text-5xl ">*/}
            {/*    Select Your Location*/}
            {/*  </h2>*/}
            {/*  <div className="mt-8 w-full">*/}
            {/*    <Select*/}
            {/*        value={selectedLGA}*/}
            {/*        onChange={handleLGAChange}*/}
            {/*        className="w-full rest_searchDrawer"*/}
            {/*        placeholder="Select Location"*/}
            {/*        disabled={all_lgas.length === 0} // Disable if no LGAs are available*/}
            {/*    >*/}
            {/*      {all_lgas.length === 0 ? (*/}
            {/*          <Select.Option value="" disabled>*/}
            {/*            Select Location*/}
            {/*          </Select.Option>*/}
            {/*      ) : (*/}
            {/*          all_lgas.map((lga) => (*/}
            {/*              <Select.Option key={lga.id} value={lga.city_name}>*/}
            {/*                {lga.city_name}*/}
            {/*              </Select.Option>*/}
            {/*          ))*/}
            {/*      )}*/}
            {/*    </Select>*/}
            {/*  </div>*/}
            {/*  <div className="mt-8 w-full">*/}
            {/*    <ul className="list-disc pl-5 space-y-2 mt-2">*/}
            {/*      <li className="marker:text-[#44843F]">*/}
            {/*        Filter restaurants based on location.*/}
            {/*      </li>*/}
            {/*    </ul>*/}

            {/*  </div>*/}

            {/*</Modal>*/}


        </>
    )
}

export default Restaurant