import Footer from "../components/redesign/footer";
import Layout from "../components/redesign/layout"
import LandingNavigation from "../components/redesign/nav";

const Homepage = () => {
    return (
        <>
            <div>
                <LandingNavigation />
                <div className="homepage-hero bg-[#063F29] h-[90vh] md:h-[750px] pt-24 px-6 md:px-0">
                    <h2 className="md:w-[60%] leading-snug md:leading-snug mx-auto text-white text-4xl md:text-6xl capitalize text-center mb-3 font-bold">Hungry? No stress, we go run am sharp sharp!</h2>
                    <p className="text-white opacity-80 text-sm md:text-base leading-loose md:leading-loose md:w-[45%] mx-auto text-center">
                        Craving something delicious? Order from your favorite restaurants and get it delivered hot, fast, and without wahala!
                        {/* Lorem ipsum dolor sit amet consectetur adipiscing elit ugue quam diam vitae velit bibendum elementum eget non vivamus volutpat odio cras vestibulum. */}
                    </p>
                    <div className="flex flex-col md:flex-row w-full md:w-max mx-auto gap-3 mt-3">
                        <button className="bg-white text-[#003D29] px-8 font-medium py-4 text-sm rounded-lg">Get Started For Free</button>
                        <button className="bg-transparent border-2 border-solid border-white text-white px-8 py-4 font-medium text-sm rounded-lg">Download Mobile App</button>
                    </div>
                    <div className="-mb-[10rem] h-[15rem] md:h-[25rem] w-[90%] md:w-[60%] mx-auto mt-12 flex">
                        <div className="w-full h-full rounded-2xl bg-[#F2F2F2]"></div>
                    </div>
                </div>
                <div className="h-[12rem] md:h-[15rem]"></div>
                <div className="text-center">
                    <div className="px-6 md:px-0 md:w-[60%] mx-auto">
                        <h3 className="font-medium text-2xl md:text-4xl md:font-bold leading-tight mb-3 md:mb-6">Local Vibes, Continental Taste.
                        </h3>
                        <h3 className="font-medium text-2xl md:text-4xl md:font-bold leading-tight mb-4 md:mb-8">For Home, Office & Anywhere.
                        </h3>
                        <p className="text-sm md:text-lg leading-loose md:leading-relaxed md:w-[80%] mx-auto mb-10 md:mb-20">
                            Jollof, amala, shawarma, burger—we deliver it all! Whether na office lunch or dinner at home, your favorite meals reach you hot and fast. No stress!
                        </p>
                    </div>
                    <div className="flex flex-col md:grid grid-cols-3 gap-10 md:gap-20 px-6 md:px-32">
                        <div>
                            <div className="size-32 mb-3 md:mb-10 mx-auto bg-[#F2F2F2] rounded-full"></div>
                            <h3 className="text-xl mb-3 font-medium">Local Dishes</h3>
                            <p className="text-[15px] leading-loose md:leading-loose">
                                Enjoy the best of Nigerian flavors—amala, jollof, ofada, nkwobi, and more. Cooked just right and delivered hot to your doorstep.
                            </p>
                        </div>
                        <div>
                            <div className="size-32 mb-3 md:mb-10 mx-auto bg-[#F2F2F2] rounded-full"></div>
                            <h3 className="text-xl mb-3 font-medium">Continental Dishes</h3>
                            <p className="text-[15px] leading-loose md:leading-loose">
                                Craving pasta, shawarma, or a juicy burger? We&apos;ve got all your favorite international meals, prepared fresh and delivered fast.
                            </p>
                        </div>
                        <div>
                            <div className="size-32 mb-3 md:mb-10 mx-auto bg-[#F2F2F2] rounded-full"></div>
                            <h3 className="text-xl mb-3 font-medium">Snacks and Drinks</h3>
                            <p className="text-[15px] leading-loose md:leading-loose">
                                From small chops to meat pies and chilled drinks, we’ve got the perfect bites and sips for any time of the day.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="bg-[#012215] px-6 md:px-24 pt-28 md:pt-32 pb-28 md:pb-0 mt-20">
                    <div className="flex flex-col md:grid grid-cols-2 gap-10 md:gap-20">
                        <div>
                            <div className="bg-[#F9F9F9] w-full h-[20rem] md:h-full"></div>
                        </div>
                        <div className="pb-44">
                            <p className="text-sm md:text-base mb-3 uppercase text-white opacity-80">Quick & Reliable</p>
                            <h3 className="text-3xl md:text-5xl font-medium md:w-[70%] mb-3 leading-tight md:leading-tight text-white">
                                Fast Delivery, No Excuses.
                            </h3>
                            <p className="text-sm md:text-base leading-loose md:leading-loose text-white opacity-80">
                                We know hunger doesn&apos;t wait, so why should you? At the heart of our service is speed. Whether you&apos;re working hard at the office or winding down at home, our delivery team makes sure your food gets to you fast, hot, and fresh. No more long waits or cold meals—just fast, reliable service every time.
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col-reverse md:grid grid-cols-2 gap-10 md:gap-20 md:mt-20">
                        <div className="md:pb-52">
                            <p className="text-sm md:text-base mb-3 uppercase text-white opacity-80">Earn extra cash</p>
                            <h3 className="text-3xl md:text-5xl font-medium md:w-[70%] mb-3 leading-tight md:leading-tight text-white">
                                Refer Friends and Earn Cash Daily
                            </h3>
                            <p className="text-sm md:text-base leading-loose md:leading-loose text-white opacity-80">
                                You can earn daily referral income into your wallet whenever your referee makes any transactions. The more you refer, the likelihood of earning from our monthly giveaways.
                            </p>
                        </div>
                        <div>
                            <div className="bg-[#F9F9F9] -mt-36 w-full h-[20rem] md:h-full"></div>
                        </div>
                    </div>
                </div>
                <div className="my-28 md:mt-40 md:mb-32 px-6 md:px-40">
                    <div className="bg-[#B1E5D5] pb-10 md:pb-0 md:h-[25rem] mx-auto rounded-xl">
                        <div className="flex flex-col-reverse md:grid grid-cols-2 gap-20 items-center px-6 md:px-20">
                            <div>
                                <h3 className="text-3xl md:w-[80%] font-medium leading-normal md:leading-normal mb-3">Get Konnect, Get Your Food Faster!</h3>
                                <p className="text-base leading-relaxed mb-4">
                                    Download Konnect for faster orders, exclusive deals, and real-time tracking. Available on iOS and Android!
                                </p>
                                <div className="flex gap-3">
                                    <button className="bg-black text-sm text-white px-8 py-3 rounded-xl">Download Android</button>
                                    <button className="bg-transparent border-2 border-solid border-black text-sm text-black px-8 py-3 rounded-xl">Download iOS</button>
                                </div>
                            </div>
                            <div>
                                <div className="hidden md:block bg-black h-[30rem] w-full -mt-[5rem] rounded-t-3xl"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Homepage;