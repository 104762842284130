import { Link } from "react-router-dom";

import Logo from '../../assets/images/logo.png';
import { ReactComponent as BellIcon } from '../../assets/images/icons/bell.svg';
import { ReactComponent as MobileNotificationIcon } from '../../assets/images/icons/mobilenotification.svg';

import { Badge, Space } from 'antd';
import { useSelector } from "react-redux";
import { useState } from "react";
import { default as AppRoutes, default as routes } from "../routes";
import { MdOutlineLocationOn } from "react-icons/md";


const MainNav = () => {

    const userData = useSelector((state) => state.userProfile.dataFetched);
    const [notificationData, setNotificationData] = useState([]);
    const [openNotificationModal, setOpenNotificationModal] = useState(false);


    return (
        <div className="bg-white border-b-2 border-solid border-[#DBDADE] py-5 md:px-20 ">
            <div className="flex justify-between items-center">
                <div className="flex items-center gap-10">
                    <Link

                        to={`/`}>
                        <img src={Logo} className="h-[2em] w-auto" alt="konnect logo" />
                    </Link>
                    <div className="flex gap-2 items-center">
                        <MdOutlineLocationOn className="text-xl" />
                        <p className="text-sm">Click here to add location</p>
                    </div>
                </div>
                {/* <div className="hidden md:block">
                    <ul className="flex gap-12">
                        <li>
                            <Link to="/" className="text-sm">Home</Link>
                        </li>
                        <li>
                            <Link to="/" className="text-sm">Restaurants</Link>
                        </li>
                        <li>
                            <Link to="/" className="text-sm">Shop</Link>
                        </li>
                        <li>
                            <Link to="/" className="text-sm">Wise9ja</Link>
                        </li>
                    </ul>
                </div> */}
                <div className="hidden md:block">
                    <ul className="flex items-center gap-10">
                        <li>
                            <div className="w-4rem"></div>
                        </li>
                        <li>
                            {userData ? (
                                <div onClick={() => setOpenNotificationModal(true)}>
                                    <Badge count={notificationData.length}>
                                        <BellIcon style={{ height: '2rem' }} />
                                    </Badge>
                                </div>
                            ) : (
                                <Link to={`${AppRoutes.signin}`}>
                                    <MobileNotificationIcon className="auth-na" style={{ height: '2.2rem' }} />
                                </Link>
                            )}
                        </li>
                        <li>
                            <Link to="/" className="bg-main text-white py-4 px-8 rounded-lg text-sm">Start Ordering</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
};

export default MainNav;