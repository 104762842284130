import { useContext, useEffect, useState } from "react";
import { Input, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Layout from "../../components/redesign/layout";
import React from "react";
import { Link } from "react-router-dom";
import SignupForm from "../../components/redesign/auth/signup";

export default function Options() {

    const validator = yup.object().shape({
        workEmail: yup.string().email('Email is not valid').required('Please enter your work email'),
        firstName: yup.string().required('Please enter your first name'),
        lastName: yup.string().required('Please enter your last name'),
        password: yup.string().required('Please enter your password'),
        mobileNumber: yup.string().required('Please enter your mobile number')
    });

    const [currentView, setCurrentView] = useState("options");
    const [currentSelection, setCurrentSelection] = useState("");
    const [loadingBar, setLoadingBar] = useState(false);
    const [loadLoginAction, setLoadLoginAction] = useState(false);

    const handlePageRouting = () => {
        setCurrentView(currentSelection);
    }

    return (
        <Layout>
            <>
                <div className="flex flex-col gap-4 min-h-[65vh] md:min-h-[85vh] md:items-center justify-center">
                    <div className="max-w-[1152px] md:w-[70%] md:py-20 pt-10 pb-0 md:pb-20 mx-auto flex flex-col-reverse md:grid grid-cols-2 gap-20 md:items-center">
                        <div className="bg-black h-full min-h-[35rem]"></div>
                        <div className="px-5 md:px-0">
                            {
                                currentView === "options" ?
                                    <div className="mx-auto block">
                                        <div className="">
                                            <h1 className="font-bold text-2xl md:text-3xl text-center">What do you want to do?</h1>
                                        </div>
                                        <div className="flex flex-col md:grid grid-cols-2 gap-4 mt-10">
                                            <button onClick={() => { setCurrentSelection("business") }}
                                                className={`block w-full h-[100px] flex auth-bar items-center justify-center px-4 text-center rounded-[8px]  ${currentSelection === "business" ? "bg-main text-white" : "border-2 border-solid border-main text-main"}`}>
                                                Business
                                            </button>
                                            <button onClick={() => { setCurrentSelection("customer") }}
                                                className={`block w-full h-[100px] flex auth-bar items-center justify-center px-4 text-center rounded-[8px] ${currentSelection === "customer" ? "bg-main text-white" : "border-2 border-solid border-main text-main"}`}>
                                                Customer
                                            </button>
                                        </div>
                                        <button onClick={handlePageRouting}
                                            className="py-5 rounded-[12px] mt-10 w-full bg-main text-sm text-white block">{
                                                loadingBar ? <Spin indicator={<LoadingOutlined spin />} className="text-white" /> : "Next"}</button>
                                        <div className="text-center text-main text-sm mt-5 cursor-pointer">
                                            <Link className="text-base block" to={`/signin`}>Have an account? <span>Click here to sign in</span></Link>
                                        </div>
                                    </div>
                                    :
                                    currentView === "business" ?
                                        <SignupForm so={true} toggleView={() => setCurrentView("options")} />
                                        :
                                        <SignupForm so={false} toggleView={() => setCurrentView("options")} />
                            }
                        </div>
                    </div>
                </div>
            </>
        </Layout>
    )
}