import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className="bg-[#002316] py-16 px-6 md:px-20 ">
            <div className="flex flex-col md:flex-row justify-between md:items-end gap-10 md:gap-0">
                <div>
                    <h3 className="text-3xl md:text-5xl text-white md:w-[60%] leading-relaxed md:leading-relaxed">Settle Your Hunger, No Stress with Konnect.</h3>
                    <ul className="mt-5 grid grid-cols-2 text-center md:text-left md:flex flex-row gap-10">
                        <li className="text-base text-[#ccccc4]">
                            <Link to="">Home</Link>
                        </li>
                        <li className="text-base text-[#ccccc4]">
                            <Link to="">Restaurants</Link>
                        </li>
                        <li className="text-base text-[#ccccc4]">
                            <Link to="">Shop</Link>
                        </li>
                        <li className="text-base text-[#ccccc4]">
                            <Link to="">Wise9ja</Link>
                        </li>
                        <li className="hidden md:block text-base text-[#ccccc4]">
                            <Link to="">Profile</Link>
                        </li>
                    </ul>
                </div>
                <div>
                    <ul className="flex flex-row w-max mx-auto gap-5">
                        <li className="size-14 md:size-16 border border-solid border-[#323641] rounded-full"></li>
                        <li className="size-14 md:size-16 border border-solid border-[#323641] rounded-full"></li>
                        <li className="size-14 md:size-16 border border-solid border-[#323641] rounded-full"></li>
                        <li className="size-14 md:size-16 border border-solid border-[#323641] rounded-full"></li>
                    </ul>
                </div>
            </div>
            <div className="mt-20 h-[1px] w-full bg-[#323641]"></div>
            <p className="text-base text-[#ccccc4] mt-14">Konnect &copy; {new Date().getFullYear()}. All Rights Reserved.</p>
        </div>
    )
};

export default Footer;