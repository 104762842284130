// import 'antd/dist/antd.css'
// import "owl.carousel/dist/assets/owl.carousel.css"
// import "owl.carousel/dist/assets/owl.theme.default.css";
// import 'react-phone-input-2/lib/style.css';

import React, { useLayoutEffect } from 'react';
import { Provider } from 'react-redux'
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import DashboardGuard from './components/dashboard/DashboardGuard'
import Confirm from './components/transactions/Confirm'
import Cart from './pages/Cart'
import EachCategory from './pages/Category'
import Contact from './pages/Contact'
import FAQ from './pages/Faq'
import Privacy from './pages/Privacy'
import Search from './pages/Search'
// import SendMail from './pages/SendMail'
import Shop from './pages/Shop'
import SingleProduct from './pages/SingleProduct'
import SubCategories from './pages/SubCategories'
import Terms from './pages/Terms'
import Vendor from './pages/Vendor'
import Wise9ja from './pages/wise9ja'
import store from './store'

import PostCheckout from './components/transactions/PostCheckout'
import KYCLanding from './pages/Landing/KYCLanding'
import LandingConstruction from './pages/Landing/LandingConstruction'
import LandingPage from './pages/Langing'
import SaEmail from './pages/SL/saEmail'
import SalesLanding from './pages/SalesLanding'
import SoContact from './pages/SoContact'
import VendorLanding from './pages/VendorLanding'
import ChangePassword from './pages/changePassword'
import SignupSuccessful from './pages/signup-successful'
import Wise9jaSuccess from './pages/wise9jasuccess'
import WhyBills from './pages/minor/WhyBills'
// import HomePage from './pages/Home'
import HomePage from './redesign/homepage';
import Restaurant from './redesign/restaurant';
import Signup from './redesign/auth/signup';
import Signin from './redesign/auth/signin';
import SendMail from './redesign/auth/resetsendmail';

// import Restaurant from './pages/Restaurant'
// import Signin from './pages/Signin'
import Signup2 from './pages/signup2'

import RestaurantConstruction from './pages/Restaurant/construction'
import SinglePage from './pages/Restaurant/SinglePage'
import ReviewCheckout from './pages/Restaurant/ReviewCheckout'
import CheckoutSuccessful from './pages/Restaurant/CheckoutSuccessful'
import TrackOrder from './pages/Restaurant/TrackOrder'
import RestaurantCartPage from "./components/Restaurant/RestaurantCartPage";

// import Signin from './pages/new/signin';
// import Signup2 from './pages/new/signup'



const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Wrapper>
          <Routes>
            {/* Homepage */}
            <Route path="/*" exact element={<Navigate to="/home" />} />
            <Route path="/home" exact element={<HomePage />} />
            <Route path="/restaurant" exact element={<Restaurant />} />
            <Route path="/restaurant/:id" exact element={<SinglePage />} />
            <Route path="/restaurant/:id/checkout" exact element={<ReviewCheckout />} />
            <Route path="/restaurant/:id/checkout_successful" exact element={<CheckoutSuccessful />} />
            <Route path="/restaurant/:id/track_order" exact element={<TrackOrder />} />
            <Route path="/restaurant/:id/restaurant_cart" exact element={<RestaurantCartPage />} />
            <Route path="/restaurant2" exact element={<RestaurantConstruction />} />

            {/* Minor */}
            {/* <Route path="/joinus" element={<Vendor />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/so-contact" element={<SoContact />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} /> */}

            {/* auth */}
            <Route path="/signup" exact element={<Signup />} />
            <Route path="/signup2" exact element={<Signup />} />
            <Route path="/signin" exact element={<Signin />} />
            <Route path="/verify-email" exact element={<SendMail />} />
            <Route path="/verify-passcode" exact element={<SendMail />} />
            <Route path="/resetsendmail" exact element={<SendMail />} />
            <Route path="/auth/reset-password/:id/:token" exact element={<ChangePassword />} />
            <Route path="/signupsuccessful" exact element={<SignupSuccessful />} />
            <Route path="/fv" element={<SalesLanding />} />
            <Route path="/vendor" element={<VendorLanding />} />


            {/* bills */}
            {/* <Route path="/wise9ja" exact element={<Wise9ja />} />
            <Route path="/wise9ja-success" exact element={<Wise9jaSuccess />} />
            <Route path="/confirm" exact element={<Confirm />} />
            <Route path="/confirm-checkout" exact element={<PostCheckout />} /> */}

            {/* Restaurant */}
            {/* <Route path="/restaurant/dashboard" exact element={<DashboardGuard location="restaurant_dash" />} />
            <Route path="/restaurant/profile" exact element={<DashboardGuard location="restaurant_profile" />} />
            <Route path="/restaurant/menu" exact element={<DashboardGuard location="restaurant_menu" />} />
            <Route path="/restaurant/menuTwo" exact element={<DashboardGuard location="restaurant_menu2" />} /> */}

            {/* Profile */}
            {/* <Route path="/user/dashboard" exact element={<DashboardGuard location="dashboard" />} />
            <Route path="/loyalty" exact element={<Navigate to="/profile/loyalty" />} />
            <Route path="/profile/paybill-history" exact element={<DashboardGuard location="paybill" />} />
            <Route path="/profile/overview" exact element={<DashboardGuard location="dashboard" />} />
            <Route path="/profile/bills" exact element={<DashboardGuard location="bills" />} />
            <Route path="/why-upgrade" exact element={<WhyBills />} />
            <Route path="/profile/referral" exact element={<DashboardGuard location="referral" />} />
            <Route path="/profile/complete-kyc" exact element={<DashboardGuard location="complete_kyc" />} />
            <Route path="/profile/loyalty" exact element={<DashboardGuard location="loyalty" />} />
            <Route path="/profile/sl" exact element={<DashboardGuard location="sl" />} />
            <Route path="/profile/sa" exact element={<DashboardGuard location="sa" />} />
            <Route path="/review-order/:trans_id" exact element={<DashboardGuard location="review" />} />
            <Route path="/review" exact element={<Navigate to="/review-order" />} />
            <Route path="/order_history" exact element={<Navigate to="/profile/order_history" />} />
            <Route path="/sales/:id/" exact element={<DashboardGuard location="sa_so" />} />
            <Route path="/sales-review/:id" exact element={<DashboardGuard location="sales_review" />} />
            <Route path="/profile/order_history/" exact element={<DashboardGuard location="order_history" />} />
            <Route path="/profile/wishlist/" exact element={<DashboardGuard location="wishlist" />} />
            <Route path="/landing-construction" exact element={<LandingConstruction />} />
            <Route path="/kyc-landing" exact element={<KYCLanding />} /> */}

            {/* <Route path="/profile/dashboard" exact element={<Dashboard />} /> */}

            {/* <Route path="/review-order/:trans_id" exact element={<DashboardGuard location="review" />} />
            <Route path="/review" exact element={<Navigate to="/review-order" />} />
            <Route path="/invitaion" exact element={<SaEmail />} /> */}

            {/* shop */}
            {/* <Route path="/landing" exact element={<LandingPage />} />
            <Route path="/shop" exact element={<Shop />} />
            <Route path="/cart" exact element={<Cart />} />
            <Route path="/search" exact element={<Search />} />
            <Route path="/checkout" exact element={<DashboardGuard location="checkout" />} />
            <Route path="/product/:name/:id" exact element={<SingleProduct />} />
            <Route path="/subcategories/:subcategory_id" exact element={<SubCategories />} />
            <Route path="/categories/:category_id" exact element={<EachCategory />} /> */}
          </Routes>
        </Wrapper>
      </BrowserRouter>
    </Provider>
  )
}

export default App