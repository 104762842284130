import { Link } from "react-router-dom"

import LogoWhite from "../../assets/images/redesign/logo-white.png";
import Logo from "../../assets/images/logo.png";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Badge } from "antd";

import { default as AppRoutes, default as routes } from "../routes";
import { ReactComponent as BellIcon } from '../../assets/images/icons/bell.svg';
import { ReactComponent as MobileNotificationIcon } from '../../assets/images/icons/mobilenotification.svg';

const LandingNavigation = () => {

    const [fixedNav, setFixedNav] = useState(false);
    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [notificationData, setNotificationData] = useState([]);

    const userData = useSelector((state) => state.userProfile.dataFetched);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            const offset = window.scrollY;
            if (offset > 200) {
                setFixedNav(true);
            }
            else {
                setFixedNav(false);
            }
        })
    }, []);
    return (
        <div className={`left-0 z-30 w-full ${fixedNav ? "fixed bg-white top-0 py-6 shadow-xl" : ""}`}>
            <div className="bg-[#063F29] slider py-2">
                <div className="slider-track">
                    <h4 className="text-white text-[12px]">1000 eaters get #1,000 cash at every order</h4>
                    <h4 className="text-white text-[12px]">1000 eaters get #1,000 cash at every order</h4>
                    <h4 className="text-white text-[12px]">1000 eaters get #1,000 cash at every order</h4>
                    <h4 className="text-white text-[12px]">1000 eaters get #1,000 cash at every order</h4>
                    <h4 className="text-white text-[12px]">1000 eaters get #1,000 cash at every order</h4>
                </div>
            </div>
            <div className="flex justify-between items-center px-20 py-5">
                <div>
                    <Link href="/">
                        <img src={Logo} alt="logo" className="h-[2rem] w-auto" />
                    </Link>
                </div>
                <div className="hidden md:block">
                    <ul className="flex gap-12">
                        <li>
                            <Link to="/" className="text-black text-sm">Home</Link>
                        </li>
                        <li>
                            <Link to="/restaurant" className="text-black text-sm">Restaurants</Link>
                        </li>
                        <li>
                            <Link to="/" className="text-black text-sm">Shop</Link>
                        </li>
                        <li>
                            <Link to="/" className="text-black text-sm">Wise9ja</Link>
                        </li>
                    </ul>
                </div>
                <div className="hidden md:block">
                    <ul className="flex items-center gap-8">
                        <li>
                            {userData ? (
                                <div onClick={() => setOpenNotificationModal(true)}>
                                    <Badge count={notificationData.length}>
                                        <BellIcon style={{ height: '2rem' }} />
                                    </Badge>
                                </div>
                            ) : (
                                <Link to={`${AppRoutes.signin}`}>
                                    <MobileNotificationIcon className="auth-na" style={{ height: '1.4rem' }} />
                                </Link>
                            )}
                        </li>
                        <li>
                            <Link to="/signup2" className="bg-[#063F29] text-white py-4 px-8 rounded-lg text-sm">Start Ordering</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default LandingNavigation;