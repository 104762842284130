import '../../../assets/css/form.css';

import { ArrowRightOutlined, LoadingOutlined } from "@ant-design/icons";
import { Input, notification, Spin } from 'antd';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { signIn, _get_profile } from '../../../common/axios_services';
import Jwt_encrypt from '../../../common/Jwt_encrypt';

const SigninForm = () => {
    let params = new URLSearchParams(window?.location?.search);
    let navigate = useNavigate();
    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [fetchingData, setFetchingData] = useState(false)
    const [redirectUrl,] = useState(params.get('konnectrd') ? params.get('konnectrd') : '');
    const [redir,] = useState(params.get('redir') ? params.get('redir') : '');
    const [referralCode,] = useState(params.get('konnectrfc') ? params.get('konnectrfc') : '');
    const [useAccountID, setUseAccountID] = useState(false); // Track sign-in method (default to Account ID)

    const { handleSubmit, control } = useForm({
        defaultValue: {
            accountID: "",
            loginPin: "",
            emailAddress: "",
            password: "",
        },
    });

    const { handleSubmit: handleMobileSubmit, control: controlMobileSubmit } = useForm({
        defaultValue: {
            accountID: "",
            loginPin: "",
            emailAddress: "",
            password: "",
        },
    });

    const openNotificationWithIcon = (type, title, message) => {
        notification[type]({
            message: title ? title : '',
            description: message ? message : "",
            placement: "bottom-right"
        });
    };



    const submitMe = async e => {
        setErrorMessage('');
        setFetchingData(true);
        // let { emailAddress, password } = e;
        const signInData = useAccountID
            ? { email: e.accountID, password: e.loginPin, app: "web", device_id: "", device_type: "", role_id: 1 }
            : { email: e.emailAddress, password: e.password, app: "web", device_id: "", device_type: "", role_id: 1 };

        try {
            const response = await signIn(signInData)
            localStorage.setItem('konnect_token', Jwt_encrypt({ token: response?.data?.data?.token }))
            openNotificationWithIcon('success', response?.data?.title, response?.data?.message)
            let result = await _get_profile();
            const restaurantStatus = result?.data?.data.is_restaurant;

            if (redir) {
                navigate(`/${redir}`)
            } else if (restaurantStatus) {
                navigate(`/restaurant/dashboard`)
            } else {
                navigate(`/home`)
            }
        } catch (err) {
            setFetchingData(false);
            if (err.response) {
                if (err?.response?.data?.message?.toLowerCase().includes('not been verified')) {
                    navigate('/verify-email')
                }
                openNotificationWithIcon('error', err?.response?.data?.title, err?.response?.data?.message)
            } else {
                openNotificationWithIcon('error', "Something went wrong", "An error occurred while signing user in. Please reload page to try again")
            }

        }
    }



    return (
        <div className="form-pages">
            <div className="">
                <div className="">
                    <div className="">
                        <div className="">
                            <div className="form aut">
                                <div className="form-tex">
                                    <h3 className="text-2xl font-bold mb-8">Login to your Konnect account!</h3>
                                    {/* <p>Think Food, think Konnect. Family & friends eat cheaper. Refer friends and earn monthly.</p> */}
                                    {errorMessage ?
                                        <p className="form-error">{errorMessage}</p> : ''
                                    }
                                    {successMessage ?
                                        <p className="success-message">{successMessage}</p> : ''
                                    }
                                </div>
                                <form onSubmit={handleSubmit(submitMe)}>
                                    <div className="flex justify-between items-center mb-4">
                                        <button type="button" onClick={() => setUseAccountID(!useAccountID)} className="text-white text-[13px] font-gilroyBold focus:outline-none  py-2 px-5 rounded-xl bg-[#1f6b2b] ">
                                            {useAccountID ? 'Sign in using Email Address' : 'Sign in with Account ID'}
                                            <ArrowRightOutlined style={{ marginLeft: "3px" }} />
                                        </button>
                                    </div>
                                    <div>
                                        {useAccountID ? (
                                            <>
                                                <div className="auth-group mb-4">
                                                    <label className='py-3' htmlFor="accountID" style={{ width: '100%' }}>Account ID</label>
                                                    <Controller name="accountID" defaultValue="" control={control}
                                                        render={({ field }) => (
                                                            <Input {...field} type="text" style={{ height: "3.4rem" }} className="rounded-lg text-sm" />
                                                        )} />
                                                </div>
                                                <div className="">
                                                    <label className='py-3' htmlFor="loginPin">Login Pin</label>
                                                    <Controller name="loginPin" control={control}
                                                        render={({ field }) => (
                                                            <Input.Password {...field} defaultValue="" type="text" style={{ height: "3.4rem" }} className="rounded-lg text-sm" />
                                                        )} />
                                                </div>
                                            </>
                                        ) :
                                            (
                                                <>
                                                    <div className="auth-group mb-4">
                                                        <label htmlFor="emailAddress" style={{ width: '100%' }}>Email Address</label>
                                                        <Controller name="emailAddress" defaultValue="" control={control}
                                                            render={({ field }) => (
                                                                <Input {...field} type="email" style={{ height: "3.4rem" }} className="rounded-lg text-sm" />
                                                            )} />
                                                    </div>
                                                    <div className="">
                                                        <label htmlFor="password">Password</label>
                                                        <Controller name="password" defaultValue="" control={control}
                                                            render={({ field }) => (
                                                                <Input.Password {...field} type="password" style={{ height: "3.4rem" }} className="rounded-lg text-sm" />
                                                            )} />
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                    {/* <div className="form-group">
                                        <label htmlFor="emailAddress" style={{ width: '100%' }}>Email address</label>
                                        <Controller control={control} defaultValue="" name="emailAddress"
                                            render={({ field }) => (
                                                <Input {...field} id="emailAddress"
                                                    type="email" style={{ height: '4.5rem' }} />
                                            )
                                            } />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password">Password</label>
                                        <Controller name="password" defaultValue="" control={control}
                                            render={
                                                ({ field }) => (
                                                    <Input.Password {...field} id="password"
                                                        type="password" style={{ height: '4.5rem' }} />
                                                )
                                            } />
                                    </div> */}
                                    <div style={{ marginTop: '2rem' }}></div>
                                    <div>
                                        {
                                            !fetchingData ?
                                                <button className="bg-main text-white w-full h-[3.5rem] rounded-[4px] text-sm">
                                                    Sign in</button>
                                                :
                                                <button
                                                    className="bg-main text-white w-full h-[3.5rem] rounded-[4px] text-sm">
                                                    <Spin style={{ marginLeft: '10px' }} indicator={antIcon} /></button>
                                        }
                                    </div>
                                </form>
                                <div style={{ marginBottom: '20px' }}></div>
                                <div className="">
                                    <Link className="text-base block mb-4" to={`/signup/?konnectrfc=${referralCode}&konnectrd=${redirectUrl}`}>New customer? <span>Create your account</span></Link>
                                    {/* <Link to={`/verify-email`}>Confirm Email? <span>Use Passcode</span></Link> */}
                                    {
                                        useAccountID ? <Link to={`/resetsendmail`}>Forgot Pin? <span>Recover pin</span></Link>
                                            : <Link className="text-base block" to={`/resetsendmail`}>Lost Password? <span>Recover password</span></Link>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SigninForm