import { useContext, useEffect, useState } from "react";
import { Input, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Layout from "../../components/redesign/layout";
import React from "react";
import { Link } from "react-router-dom";
import SignupForm from "../../components/redesign/auth/signup";
import SigninForm from "../../components/redesign/auth/signin";

export default function Options() {

    const validator = yup.object().shape({
        workEmail: yup.string().email('Email is not valid').required('Please enter your work email'),
        firstName: yup.string().required('Please enter your first name'),
        lastName: yup.string().required('Please enter your last name'),
        password: yup.string().required('Please enter your password'),
        mobileNumber: yup.string().required('Please enter your mobile number')
    });

    const [currentView, setCurrentView] = useState("options");
    const [currentSelection, setCurrentSelection] = useState("");
    const [loadingBar, setLoadingBar] = useState(false);
    const [loadLoginAction, setLoadLoginAction] = useState(false);

    const handlePageRouting = () => {
        setCurrentView(currentSelection);
    }

    return (
        <Layout>
            <>
                <div className="flex flex-col gap-4 min-h-[65vh] md:min-h-[85vh] md:items-center justify-center">
                    <div className="max-w-[1152px] md:w-[70%] md:py-20 pt-10 pb-0 md:pb-20 mx-auto flex flex-col-reverse md:grid grid-cols-2 gap-20 md:items-center">
                        <div className="bg-black h-full min-h-[35rem]"></div>
                        <div className="px-5 md:px-0">
                            <SigninForm toggleView={() => setCurrentView("option")} />
                        </div>
                    </div>
                </div>
            </>
        </Layout>
    )
}