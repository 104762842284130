import Footer from "./footer";
import LandingNavigation from "./nav";
import MainNav from "./nav-main";

const Layout = (props) => {
    return (
        <div>
            {props.landingPage ? <LandingNavigation /> : <MainNav />}
            {props.children}
            <Footer />
        </div>
    )
}

export default Layout;